import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lenis from '@studio-freight/lenis';



//Preload animation
/** @format */
const svg = document.getElementById("svg");
const tl = gsap.timeline();
const curve = "M0 502S175 272 500 272s500 230 500 230V0H0Z";
const flat = "M0 2S175 1 500 1s500 1 500 1V0H0Z";

tl.from(".loader-wrap-heading h1", {
    delay: 0.1,
    y: 200,
    skewY: 10,
}).to(".loader-wrap-heading h1", {
    delay: 0.3,
    y: -200,
    skewY: 10,
});
tl.to(svg, {
    delay: 0.3,
    duration: 0.8,
    attr: { d: curve },
    ease: "power2.easeIn",
}).to(svg, {
    delay: 0.3,
    duration: 0.8,
    attr: { d: flat },
    ease: "power2.easeOut",
});
tl.to(".loader-wrap", {
    y: -1500,
});
tl.to(".loader-wrap", {
    zIndex: -1,
    display: "none",
});


//Register GSAP ScrollTrigger Plugin
gsap.registerPlugin(ScrollTrigger);

// 1st Slider Scroll
//Get the gallery from the DOM
const gallery = document.querySelector(".gallery");

//Get the gallery total width
let galleryWidth = gallery.offsetWidth;

//Get the amount to scroll horizontally by subtracting the window width from the full width of the gallery
let amountToScroll = (galleryWidth - window.innerWidth) + 50;
console.log(amountToScroll);


let mm = gsap.matchMedia();

mm.add("(min-width: 993px)", () => {
    gsap.to(gallery, {
        x: -amountToScroll,
        ease: "none",
        scrollTrigger: {
            trigger: "#sliderOne",
            start: "top top",
            end: "+=" + amountToScroll,
            pin: true,
            scrub: true,
            //markers: true,

            onUpdate: self => {
                let skewAmount = self.getVelocity() / 200;
                gsap.to(gallery, {
                    skewX: skewAmount,
                    //overwrite: true,
                    ease: "power1.out"
                })
            },

            onScrubComplete: () => {
                gsap.to(gallery, {
                    skewX: 0,
                    duration: 0.2,
                    ease: "power1.out"
                })
            }
        }
    });
})

mm.add("(min-width: 768px) and (max-width: 992px)", () => {
    gsap.to(gallery, {
        x: -amountToScroll,
        ease: "none",
        scrollTrigger: {
            trigger: "#sliderOne",
            start: "top top",
            end: "+=" + amountToScroll,
            pin: true,
            scrub: true,
            //markers: true,

            onUpdate: self => {
                let skewAmount = self.getVelocity() / 200;
                gsap.to(gallery, {
                    skewX: skewAmount,
                    //overwrite: true,
                    ease: "power1.out"
                })
            },

            onScrubComplete: () => {
                gsap.to(gallery, {
                    skewX: 0,
                    duration: 0.2,
                    ease: "power1.out"
                })
            }
        }
    });
})


// Mobile version - Désactiver l'animation en version mobile
mm.add("(max-width: 767px)", () => {
    // Ne rien faire pour désactiver l'animation en version mobile
});


// 2nd Slider Scroll

const galleryTwo = document.querySelector(".galleryTwo");
let galleryTwoWidth = galleryTwo.offsetWidth;
let amountToScrollTwo = (galleryTwoWidth - window.innerWidth) + 110;

let mmTwo = gsap.matchMedia();

//Version PC
mmTwo.add("(min-width: 993px)", () => {
    gsap.set(galleryTwo, { xPercent: -65, yPercent: 30 });

    gsap.to(galleryTwo, {
        yPercent: 0,
        duration: 1.5,
        ease: "power1.out",
        //immediateRender: false, // otherwise scrollTrigger will force the render right away and the starting values that get locked in would be affected by the from() above
        scrollTrigger: {
            trigger: ".gallery-wrapper-Two",
            start: "top center",
            end: "top top",
            scrub: true,
        }
    });

    gsap.to(galleryTwo, {
        x: amountToScrollTwo,
        ease: "none",
        scrollTrigger: {
            trigger: ".gallery-wrapper-Two",
            start: "top top",
            end: "+=" + amountToScrollTwo,
            pin: true,
            scrub: true,
        }
    });
});

//Version Tablette
mmTwo.add("(min-width: 768px) and (max-width: 992px)", () => {
    gsap.set(galleryTwo, { xPercent: -65, yPercent: 30 });

    gsap.to(galleryTwo, {
        yPercent: 0,
        duration: 1.5,
        ease: "power1.out",
        scrollTrigger: {
            trigger: ".gallery-wrapper-Two",
            start: "top center",
            end: "top top",
            scrub: true,
        }
    });

    gsap.to(galleryTwo, {
        x: amountToScrollTwo,
        ease: "none",
        scrollTrigger: {
            trigger: ".gallery-wrapper-Two",
            start: "top top",
            end: "+=" + amountToScrollTwo,
            pin: true,
            scrub: true, //Set animation progress to scroll position
        }
    });
});

// Désactiver l'animation en version mobile
mmTwo.add("(max-width: 767px)", () => {
    // Ne rien faire pour désactiver l'animation en version mobile
});


// 3rd Slider Scroll
const galleryThree = document.querySelector(".galleryThree");

let mmThree = gsap.matchMedia();

mmThree.add("(min-width: 993px)", () => {
    gsap.set(galleryThree, { xPercent: 68, yPercent: 20, opacity: 0, scale: 0 }); //opacity: 0, scale: 0 
    gsap.to(galleryThree, {
        xPercent: 0,
        yPercent: 0,
        opacity: 1,
        scale: 1,
        duration: 1,
        ease: "power1.out",
        //immediateRender: false, // otherwise scrollTrigger will force the render right away and the starting values that get locked in would be affected by the from() above
        scrollTrigger: {
            trigger: ".gallery-wrapper-Three",
            start: "top center",
            end: "top top",
            scrub: true,
            //markers: true
        }
    });
})



mmThree.add("max-width: 767px", () => {
    gsap.set(galleryThree, { xPercent: 0, yPercent: 0 });
})

//Initialize Lenis smooth scrolling
const lenis = new Lenis();

function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
}

requestAnimationFrame(raf);



//3e slider
import image1 from '../images/ohayo/slider3/1.png';
import image2 from '../images/ohayo/slider3/2.png';
import image3 from '../images/ohayo/slider3/3.png';
import image4 from '../images/ohayo/slider3/4.png';
import image5 from '../images/ohayo/slider3/5.png';
import image6 from '../images/ohayo/slider3/6.png';

const matchPc = window.matchMedia("(min-width: 993px)");

//function pcHoverEffect() {}

const canvas = document.querySelector('.canvas2');
const ctx = canvas.getContext('2d');
const links = [...document.querySelectorAll('.galleryThree h2')];



function lerp(start, end, t) {
    return start * (1 - t) + end * t;
}


let imgIndex = 0;
// Load images into an array for reference
const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
];

let imageArr = [];


// Canvas mousemove variables
let targetX = 0;
let targetY = 0;
let currentX = 0;
let currentY = 0;

window.addEventListener('mousemove', (e) => {

    if (matchPc.matches) {
        targetX = e.clientX;
        targetY = e.clientY;
        //console.log(targetX, targetY);
    }

})


images.forEach((image, idx) => {

    if (matchPc.matches) {
        let elImage = new Image(700, 450);
        elImage.src = image;


        elImage.classList.add('project-image');
        document.body.append(elImage);
        elImage.style.cssText = "object-fit: cover;";
        imageArr.push(elImage);
    }

})

// Draw images to the canvas

let percent = 0;
let target = 0;

function drawImage(idx) {

    if (matchPc.matches) {
        let { width, height } = imageArr[1].getBoundingClientRect();
        canvas.width = width;
        //(width / 2) * window.devicePixelRatio;
        console.log(canvas.width);
        canvas.height = height;
        //(height / 2) * window.devicePixelRatio;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        // pixelate by diabling the smoothing
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.msSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        if (target === 1) {
            // 2 speeds to make the effect more gradual
            if (percent < 0.2) {
                percent += .01;
            } else if (percent < 1) {
                percent += .1;
            }
        } else if (target === 0) {
            if (percent > 0.2) {
                percent -= .3;
            } else if (percent > 0) {
                percent -= .01;
            }
        }

        let scaledWidth = width * percent;
        let scaledHeight = height * percent;

        if (percent >= 1) {
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
            ctx.drawImage(imageArr[idx], 0, 0, width, height);
        } else {
            ctx.drawImage(imageArr[idx], 0, 0, scaledWidth, scaledHeight);
            ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
            if (canvas.width !== 0 && canvas.height !== 0) {
                ctx.drawImage(canvas, 0, 0, scaledWidth, scaledHeight, 0, 0, width, height);
            }
        }
    }

}

for (let i = 0; i < links.length; i++) {

    if (matchPc.matches) {
        links[i].addEventListener('mouseover', () => {
            for (let j = 0; j < links.length; j++) {
                if (j !== i) {
                    links[j].style.opacity = 0.2;
                    links[j].style.zIndex = 0;
                } else {
                    links[j].style.opacity = 1;
                    links[j].style.zIndex = 3;
                }
            }
        })

        links[i].addEventListener('mouseleave', () => {
            for (let i = 0; i < links.length; i++) {
                links[i].style.opacity = 1;
            }
        })

        links[i].addEventListener('mouseenter', () => {
            imgIndex = i;
            target = 1;
        });

        links[i].addEventListener('mouseleave', () => {
            target = 0;
        })
    }
}

function animate() {

    if (matchPc.matches) {
        currentX = lerp(currentX, targetX, 0.075);
        currentY = lerp(currentY, targetY, 0.075);
        let { width, height } = imageArr[imgIndex].getBoundingClientRect();
        canvas.style.transform = `translate3d(${currentX - (width / 2)}px, ${window.innerHeight / 4 - (height * 2)}px, 0)`; //currentY
        drawImage(imgIndex);
        window.requestAnimationFrame(animate);
    }
}

animate();



